import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import App from './App'

import './index.css'
import 'typeface-lato'

/* ---- AWS Amplify for Cognito GUEST Authentication ---- */
import Amplify from 'aws-amplify'
Amplify.configure({
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id: 'eu-central-1:7ba976d7-44ff-46f9-b869-1b606280afd3',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_LQ4QPeGe6',
  aws_user_pools_web_client_id: '6o7r15rqdspba28fpjdol8esm0',
  aws_cognito_verification_mechanisms: ['EMAIL']
})
/* -------------------------------------------------------- */

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
