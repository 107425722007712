import { Auth } from 'aws-amplify'
import { useState } from 'react'
import Footer from './components/Footer'
import OfferPanel from './components/OfferPanel'
import OfferPanelGeneric from './components/OfferPanelGeneric'
import Tools from './Tools'
import './OfferPage.css'

export default function OfferPage({ offer }) {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [selectedOption, selectOption] = useState(
    offer.roundtrips.find(
      (trip) => trip.recommendation && trip.recommendation.best_match
    )
  )

  const sectionAvailable = (section) => {
    if (
      section === 'habit_to_beat' &&
      offer.roundtrips.find(
        (trip) => trip.recommendation && trip.recommendation.habit_to_beat
      )
    )
      return true
    if (
      section === 'best_match' &&
      offer.roundtrips.find(
        (trip) => trip.recommendation && trip.recommendation.best_match
      )
    )
      return true
    if (
      section === 'better_match' &&
      offer.roundtrips.find(
        (trip) => trip.recommendation && trip.recommendation.better_match
      )
    )
      return true
    if (
      section === 'max_savings' &&
      offer.roundtrips.find(
        (trip) => trip.recommendation && trip.recommendation.max_savings
      )
    )
      return true
    if (
      section === 'train' &&
      offer.roundtrips.find(
        (trip) => trip.trip_options && trip.trip_options.train
      )
    )
      return true
    if (
      section === 'online' &&
      offer.roundtrips.find(
        (trip) => trip.trip_options && trip.trip_options.online
      )
    )
      return true
    return false
  }

  const getTrainAndOnlineOptionToCompareTo = () => {
    // Ideally, we'd have a BEST MATCH. If that's not available, let's try the others...
    var option = offer.roundtrips.find(
      (trip) => trip.recommendation && trip.recommendation.best_match
    )
    if (!option)
      option = offer.roundtrips.find(
        (trip) => trip.recommendation && trip.recommendation.better_match
      )
    if (!option)
      option = offer.roundtrips.find(
        (trip) => trip.recommendation && trip.recommendation.max_savings
      )
    if (!option)
      option = offer.roundtrips.find(
        (trip) => trip.recommendation && trip.recommendation.habit_to_beat
      )
    return option
  }

  const confirmOffer = async (optionSelected) => {
    // Which index is the selected offer?
    var optionIndex = 0
    offer.roundtrips.forEach((o, i) => {
      if (o === optionSelected) optionIndex = i
    })
    setLoading(true)
    try {
      // We need a guest user for Cognito to get this through, so let's fetch that first
      const user = await Auth.signIn('guest@example.com', 'Guest_Password1')
      const response = await fetch(
        document.location.hostname.indexOf('localhost') >= 0
          ? 'http://localhost:8080/v1/updateOfferStatus'
          : 'https://mzayppkcxk.execute-api.eu-central-1.amazonaws.com/dev/v1/updateOfferStatus',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
          body: JSON.stringify({
            agency_id: offer.agency_id,
            traveler_id: offer.customer_id,
            readable_id: offer.readable_id,
            status: 'accepted',
            selected: optionIndex,
          }),
        }
      )

      if (response) {
        setLoading(false)
        setSubmitted(true)
      }
    } catch (error) {
      console.log('Unable to reach the confirmation endpoint', error)
    }
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: 200 }}>
        <img alt='loading' src='/loader.gif' />
      </div>
    )
  }

  if (submitted) {
    return (
      <div style={{ textAlign: 'center', marginTop: 300, padding: 30 }}>
        <div style={{ fontWeight: 'bold', marginBottom: 20 }}>Thank you.</div>
        Your travel agent will be in touch soon.
      </div>
    )
  }

  return (
    <div>
      <div className='offer-page-container'>
        <div className='offer-page-inner-container'>
          <img
            className='logo'
            src='/logo.png'
            height='30'
            style={{ flexShrink: 0, marginTop: 25 }}
            alt='logo'
          />
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ padding: 0 }}>
            <img className='leaf' src='/leaf_small.svg' alt='Your Impact' />
          </div>
          <div className='top-box-container'>
            <div className='your-impact'>
              <div style={{ fontSize: 12 }}>Your</div>
              <div style={{ fontWeight: 'bold', fontSize: 18 }}>Impact</div>
            </div>
            <div className='top-box-fnz-points'>
              <div style={{ fontSize: 12, marginBottom: 5, color: '#0A1EAA' }}>
                Climate Points
              </div>
              <div
                style={{
                  fontWeight: 900,
                  letterSpacing: 0.5,
                  fontSize: 18,
                  color: '#0A1EAA',
                }}
              >
                {Tools.formatNumber(
                  (selectedOption && selectedOption.fnz_points) || 0
                )}
              </div>
            </div>
            <div className='top-box-co2-balance'>
              <div style={{ fontSize: 12, marginBottom: 5, color: '#0A1EAA' }}>
                CO₂ Balance
              </div>
              <div
                style={{
                  fontWeight: 900,
                  letterSpacing: 0.5,
                  fontSize: 18,
                  color: '#0A1EAA',
                }}
              >
                {Tools.formatNumber(
                  (selectedOption && selectedOption.climate_impact) || 0
                )}{' '}
                kgs
              </div>
            </div>
          </div>
        </div>

        <div className='top-intro'>
          <div className='top-hi'>Hi,</div>
          <div className='top-subtitle'>
            Check out our Climate Options for your next flight.
          </div>
        </div>

        <div className='trip-details'>
          <div className='trip-details-column'>
            <div style={{ display: 'flex' }}>
              <div className='left-detail-column'>Departure</div>
              <div className='right-detail-column'>
                {offer.roundtrips[0].outward_itinerary.segments[0].origin}
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 8 }}>
              <div className='left-detail-column'>Destination</div>
              <div className='right-detail-column'>
                {
                  offer.roundtrips[0].outward_itinerary.segments[
                    offer.roundtrips[0].outward_itinerary.segments.length - 1
                  ].destination
                }
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 8 }}>
              <div className='left-detail-column'>Outward Flight</div>
              <div className='right-detail-column'>
                {Tools.formatTravelDate(
                  offer.roundtrips[0].outward_itinerary.segments[0]
                    .departure_time
                )}
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 8 }}>
              <div className='left-detail-column'>Return Flight</div>
              <div className='right-detail-column'>
                {Tools.formatTravelDate(
                  offer.roundtrips[0].return_itinerary.segments[0]
                    .departure_time
                )}
              </div>
            </div>
          </div>

          <div className='trip-details-column'>
            <div style={{ display: 'flex' }}>
              <div className='left-detail-column'>Travel Agency</div>
              <div className='right-detail-column'>{offer.agency.name}</div>
            </div>
            <div style={{ display: 'flex', marginTop: 8 }}>
              <div className='left-detail-column'>Contact Person</div>
              <div className='right-detail-column'>
                <div className='agency-person-name'>
                  {offer.agency.employee.name}
                  {offer.agency.employee.phone ? ',' : ''}
                </div>
                <div className='agency-person-phone'>
                  {offer.agency.employee.phone}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 8, height: 19 }}></div>
            <div style={{ display: 'flex', marginTop: 8 }}>
              <div className='left-detail-column'>Offer ID</div>
              <div className='right-detail-column'>
                {offer.readable_id.date}-{offer.readable_id.customer_acronym}-
                {offer.readable_id.counter}
              </div>
            </div>
          </div>
        </div>

        <div className='offer-panel-container'>
          {sectionAvailable('best_match') && (
            <OfferPanel
              section='best_match'
              offer={offer}
              selected={
                selectedOption &&
                selectedOption.recommendation &&
                selectedOption.recommendation.best_match
              }
              onSelect={() => {
                selectOption(
                  offer.roundtrips.find(
                    (trip) =>
                      trip.recommendation && trip.recommendation.best_match
                  )
                )
              }}
              onConfirm={() => {
                confirmOffer(selectedOption)
              }}
            />
          )}

          {sectionAvailable('better_match') && (
            <OfferPanel
              section='better_match'
              offer={offer}
              selected={
                selectedOption &&
                selectedOption.recommendation &&
                selectedOption.recommendation.better_match
              }
              onSelect={() => {
                selectOption(
                  offer.roundtrips.find(
                    (trip) =>
                      trip.recommendation && trip.recommendation.better_match
                  )
                )
              }}
              onConfirm={() => {
                confirmOffer(selectedOption)
              }}
            />
          )}

          {sectionAvailable('habit_to_beat') && (
            <OfferPanel
              section='habit_to_beat'
              offer={offer}
              selected={
                selectedOption &&
                selectedOption.recommendation &&
                selectedOption.recommendation.habit_to_beat
              }
              onSelect={() => {
                selectOption(
                  offer.roundtrips.find(
                    (trip) =>
                      trip.recommendation && trip.recommendation.habit_to_beat
                  )
                )
              }}
              onConfirm={() => {
                confirmOffer(selectedOption)
              }}
            />
          )}

          {sectionAvailable('max_savings') && (
            <OfferPanel
              section='max_savings'
              offer={offer}
              selected={
                selectedOption &&
                selectedOption.recommendation &&
                selectedOption.recommendation.max_savings
              }
              onSelect={() => {
                selectOption(
                  offer.roundtrips.find(
                    (trip) =>
                      trip.recommendation && trip.recommendation.max_savings
                  )
                )
              }}
              onConfirm={() => {
                confirmOffer(selectedOption)
              }}
            />
          )}

          {sectionAvailable('train') && (
            <OfferPanelGeneric
              section='option_train'
              compareTo={getTrainAndOnlineOptionToCompareTo()}
              selected={
                selectedOption &&
                selectedOption.trip_options &&
                selectedOption.trip_options.train
              }
              onSelect={() => {
                selectOption(
                  offer.roundtrips.find(
                    (trip) => trip.trip_options && trip.trip_options.train
                  )
                )
              }}
              onConfirm={() => {
                confirmOffer(selectedOption)
              }}
            />
          )}

          {sectionAvailable('online') && (
            <OfferPanelGeneric
              section='option_online'
              compareTo={getTrainAndOnlineOptionToCompareTo()}
              selected={
                selectedOption &&
                selectedOption.trip_options &&
                selectedOption.trip_options.online
              }
              onSelect={() => {
                selectOption(
                  offer.roundtrips.find(
                    (trip) => trip.trip_options && trip.trip_options.online
                  )
                )
              }}
              onConfirm={() => {
                confirmOffer(selectedOption)
              }}
            />
          )}
        </div>
      </div>

      <Footer />
    </div>
  )
}
